import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import { graphql } from 'gatsby'

export default function PrivacyPolicyPage({ data: { policy } }) {
  return (
    <Layout className='with-background'>
      <SEO title='PrivacyPolicy' />
      <Spacer />
      <div className='text-center p20 max-450 ma privacy-content balance' dangerouslySetInnerHTML={{ __html: policy.privacyPolicy }} />
      <Spacer className='mh-30' />
      <div className='pl40 pr40 m-pl20 m-pr20'>
        <div className='bb1' />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyQuery {
    policy: datoCmsPrivacyPolicy {
      privacyPolicy
    }
  }
`
